import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { createContext, useCallback, useState, useEffect } from "react";

import { AuthStaff, newAuthStaff } from "src/models";

interface IAuth {
  authenticate: () => Promise<void>;
  member: AuthStaff | null | undefined;
  unauthenticate: () => void;
}

export const AuthContext = createContext<IAuth>({
  authenticate: async () => {},
  member: undefined,
  unauthenticate: async () => {},
});

interface IProps {
  children?: React.ReactNode;
}

export const AuthContextProvider = ({ children }: IProps) => {
  // The member is undefined until the authentication state is know,
  // whereupon the member is either known or null.
  const [member, setMember] = useState<AuthStaff | null | undefined>(undefined);
  const queryClient = useQueryClient();

  const unauthenticate = () => {
    queryClient.resetQueries();
    queryClient.clear();
    setMember(null);
  };

  const authenticate = useCallback(async () => {
    try {
      const response = await axios.get<any>("/v1/staff/current/");
      const member = newAuthStaff(response.data);
      setMember(member);
    } catch (e) {
      setMember(null);
    }
  }, [setMember]);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <AuthContext.Provider value={{ authenticate, member, unauthenticate }}>
      {children}
    </AuthContext.Provider>
  );
};
