import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { Brand, Form, Species } from "src/models";
import { useEditFormulaMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const EditFormula = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaMutation(formulaId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        brand: data.brand as Brand,
        form: data.form as Form,
        nameInternal: data.nameInternal,
        nameUk: data.nameUk.trim() === "" ? null : data.nameUk,
        species: data.species as Species,
      });
      addToast("Formula edited", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          {
            to: `/formulas/${formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
          { label: "Edit formula" },
        ]}
        title="Edit formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            brand: formula?.brand ?? Brand.CURALEAF,
            form: formula?.form ?? Form.FLOWER,
            nameInternal: formula?.nameInternal ?? "",
            nameUk: formula?.nameUk ?? "",
            species: formula?.species ?? Species.INDICA,
          }}
          disabled={formula === undefined}
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditFormula;
