import {
  sortByKey,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { Formula } from "src/models";
import { useFormulasQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "brand" | "nameInternal";

const Formulas = () => {
  const { data: formulas } = useFormulasQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("nameInternal");

  const sortKey = (formula: Formula) => [formula[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={2} />;

  if (formulas !== undefined && formulas.length > 0) {
    rows = formulas.sort(sortByKey(sortKey, order)).map((formula) => (
      <TableRow key={formula.id}>
        <TableCell>
          <Link component={WLink} to={`/formulas/${formula.id}/`}>
            {formula.nameInternal}
          </Link>
        </TableCell>
        <TableCell>{formula.brand}</TableCell>
      </TableRow>
    ));
  } else if (formulas !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={2}>No formulas found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Title
        actions={
          <Button component={WLink} to="/formulas/new/" variant="contained">
            New
          </Button>
        }
        title="Formulas"
      />
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "nameInternal"}
                    direction={order}
                    onClick={onSortClick("nameInternal")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "brand"}
                    direction={order}
                    onClick={onSortClick("brand")}
                  >
                    Brand
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Formulas;
