import { z } from "zod";

import { StaffRole } from "src/models/staff";

const allEndpointRolesSchema = z.object({
  allRoles: z.array(z.nativeEnum(StaffRole)),
  endpointRoles: z.array(
    z.object({
      endpoint: z.string(),
      accessibleTo: z.array(z.nativeEnum(StaffRole)),
    }),
  ),
});

export type AllEndpointRoles = z.infer<typeof allEndpointRolesSchema>;
export const newAllEndpointRoles = (data: unknown): AllEndpointRoles =>
  allEndpointRolesSchema.parse(data);
