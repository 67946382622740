import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Brand, Form, Species } from "src/models";

const FormSchema = z.object({
  brand: z.string(),
  form: z.string(),
  nameInternal: z.string().trim().min(1),
  nameUk: z.string(),
  species: z.string(),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const FormulaForm = ({ defaultValues, disabled, onSubmit }: IProps) => {
  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Internal name"
            name="nameInternal"
            required
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="UK market name"
            name="nameUk"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Brand"
            name="brand"
            options={Object.values(Brand).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Form"
            name="form"
            options={Object.values(Form).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Species"
            name="species"
            options={Object.values(Species).map((value) => ({ value }))}
            required
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label="Add" />
          <FormLinks links={[{ label: "Back", to: "/formulas/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default FormulaForm;
