import { ScrollToTop, NotFound } from "@curaleaf-international/components";
import { Route, Switch } from "wouter";

import PageWrapper from "src/components/PageWrapper";
import PrivateRoute from "src/components/PrivateRoute";
import ApproveFormulaHistory from "src/pages/ApproveFormulaHistory";
import CreateFormula from "src/pages/CreateFormula";
import DisableStaff from "src/pages/DisableStaff";
import EditFormula from "src/pages/EditFormula";
import EditStaffMember from "src/pages/EditStaffMember";
import Formula from "src/pages/Formula";
import Formulas from "src/pages/Formulas";
import HelpEndpointRoles from "src/pages/HelpEndpointRoles";
import Login from "src/pages/Login";
import Staff from "src/pages/Staff";
import StaffMember from "src/pages/StaffMember";

const ThrowError = () => {
  throw new Error("Test Error");
};

const UUID_REGEX =
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

const Router = () => (
  <PageWrapper>
    <ScrollToTop />
    <Switch>
      <PrivateRoute path="/">Hello</PrivateRoute>
      <PrivateRoute
        path={RegExp(`^/formula-histories/(?<id>${UUID_REGEX})/approve/$`)}
      >
        {(params: any) => (
          <ApproveFormulaHistory formulaHistoryId={params.id} />
        )}
      </PrivateRoute>
      <PrivateRoute path="/formulas/">
        <Formulas />
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/$`)}>
        {(params: any) => <Formula formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path={RegExp(`^/formulas/(?<id>${UUID_REGEX})/edit/$`)}>
        {(params: any) => <EditFormula formulaId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/formulas/new/">
        <CreateFormula />
      </PrivateRoute>
      <PrivateRoute path="/staff/">
        <Staff />
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/"}>
        {(params: any) => <StaffMember staffId={params.id} />}
      </PrivateRoute>
      <PrivateRoute path="/staff/:id/edit/roles/">
        {(params: any) => <EditStaffMember staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path={"/staff/:id/disabled/"}>
        {(params: any) => <DisableStaff staffId={parseInt(params.id)} />}
      </PrivateRoute>
      <PrivateRoute path="/help/endpoint-roles/">
        <HelpEndpointRoles />
      </PrivateRoute>
      <Route path="/login/">
        <Login />
      </Route>
      <Route path="/control/fe-error/">
        <ThrowError />
      </Route>
      <Route path="/(.*)">
        <NotFound />
      </Route>
    </Switch>
  </PageWrapper>
);

export default Router;
