import {
  SelectField,
  SubmitButton,
  TextField,
  ToastContext,
  FormLinks,
  Title,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { StaffRole } from "src/models";
import { useStaffQuery, useUpdateStaffRolesMutation } from "src/queries";

interface IProps {
  staffId: number;
}

const FormSchema = z.object({
  roles: z.array(z.string()),
  reason: z.string().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditStaffMember = ({ staffId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: staff } = useStaffQuery(staffId);
  const { mutateAsync: updateMember } = useUpdateStaffRolesMutation(staffId);

  const roles = Object.keys(StaffRole);

  const initialValues = {
    roles,
    reason: "",
  };

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    disabled: staff === undefined,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(initialValues), [methods, staff]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateMember({
        roles: data.roles as StaffRole[],
        reason: data.reason,
      });
      setLocation("/staff/");
      addToast("Staff member updated", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        addToast("You cannot set your own roles.", "error");
      } else {
        addToast("Try Again", "error");
      }
    }
  };

  return (
    <>
      <Title title="Edit Staff Member Roles" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <SelectField
                label="Roles"
                multiple={true}
                name="roles"
                options={roles.map((role) => ({
                  value: role,
                }))}
              />
              <TextField
                autoComplete="off"
                fullWidth
                label="Reason"
                name="reason"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Edit" />
              <FormLinks
                links={[{ label: "Back", to: `/staff/${staffId}/` }]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default EditStaffMember;
