import axios from "axios";

import { newAllEndpointRoles, AllEndpointRoles } from "src/models";
import { useQuery } from "src/query";

export const useEndpointRolesQuery = () =>
  useQuery<AllEndpointRoles>({
    queryKey: ["endpoint-roles"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/utils/endpoint-roles/", { signal });
      return newAllEndpointRoles(response.data);
    },
  });
