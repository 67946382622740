import { formatDateTime, Value } from "@curaleaf-international/components";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useStaffQuery } from "src/queries/staff";

interface IProps {
  staffId: number;
}

const Details = ({ staffId }: IProps) => {
  const { data: staff } = useStaffQuery(staffId);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>
            <Value text={staff?.email} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Created</TableCell>
          <TableCell>
            <Value dateTime={staff?.created} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Login</TableCell>
          <TableCell>
            {staff === undefined ? (
              <Skeleton />
            ) : staff.lastLogin === null ? (
              "No login"
            ) : (
              formatDateTime(staff?.lastLogin)
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Active Sessions</TableCell>
          <TableCell>
            <Value text={staff?.activeSessions.toString()} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Disabled</TableCell>
          <TableCell>
            {staff === undefined ? (
              <Skeleton />
            ) : staff.disabled === null ? (
              "Not disabled"
            ) : (
              formatDateTime(staff?.disabled)
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Details;
