import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { Brand, Form, Species } from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const CreateFormula = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateFormulaMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        brand: data.brand as Brand,
        form: data.form as Form,
        nameInternal: data.nameInternal,
        nameUk: data.nameUk.trim() === "" ? null : data.nameUk,
        species: data.species as Species,
      });
      addToast("Formula added", "success");
      setLocation("/formulas/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          { label: "Add formula" },
        ]}
        title="Add formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            brand: Brand.CURALEAF,
            form: Form.FLOWER,
            nameInternal: "",
            nameUk: "",
            species: Species.INDICA,
          }}
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateFormula;
